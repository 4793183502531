<script setup lang="ts">
import type { TournamentData } from "@/types";
const { t } = useT();

const props = defineProps<{
	image: string;
	coins: number;
	entries: number;
	tournament?: TournamentData;
	sweepStakes?: boolean;
	subTitle: string;
	title: string;
}>();

const { durationLeft, reset } = useCountdown({
	timestamp: (props.tournament?.isActive ? props.tournament?.end : props.tournament?.start) ?? 0
});

watch(
	() => props.tournament?.historyId,
	() => {
		reset((props.tournament?.isActive ? props.tournament?.end : props.tournament?.start) ?? 0);
	}
);
</script>
<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			src: image,
			format: 'avif',
			alt: 'card-tournament'
		}"
		height="257px"
		:class="['card-tournament', sweepStakes ? 'card-entries' : '']"
	>
		<template #default>
			<ABadge background="var(--carrara)" variant="skew" class="top-left" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Free to play") }}</AText>
			</ABadge>
			<ACornerBadge variant="custom" modifiers="right" class="top-right">
				<AText variant="tallinn" :modifiers="['uppercase', 'normal']">{{ t("every") }}</AText>
				<AText variant="topeka" :modifiers="['bold']">{{ sweepStakes ? t("8 hours") : t("2 hours") }}</AText>
			</ACornerBadge>
		</template>

		<template #top>
			<div class="block">
				<AText variant="ternopil" :modifiers="['normal', 'uppercase']" class="title">
					{{ title }}
				</AText>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']" class="sub-title">
					{{ subTitle }}
				</AText>
				<div v-if="tournament?.prize" class="card-count coins">
					<AText variant="topeka" class="text-chittagong count-before" :modifiers="['bold', 'uppercase']">{{
						t("Prize pool")
					}}</AText>
					<NuxtImg src="/nuxt-img/cards/coins.png" alt="coins" format="webp" width="28" height="28" />
					<AText variant="tanzay" class="text-chittagong" :modifiers="['black']">
						{{ numberFormat(parseInt(tournament.prize)) }}
					</AText>
				</div>
				<div v-if="tournament?.prize" class="card-count entries">
					<AText variant="topeka" class="text-cixi count-before" :modifiers="['bold', 'uppercase']">{{
						t("Prize pool")
					}}</AText>
					<NuxtImg src="/nuxt-img/cards/entries.png" alt="entries" format="webp" width="28" height="28" />
					<AText variant="tanzay" class="text-cixi" :modifiers="['black']">
						{{ numberFormat(parseInt(tournament.prize)) }}
					</AText>
				</div>
			</div>
		</template>

		<template #bottom>
			<AText variant="topeka" as="div" :modifiers="['center']" class="sweep-stakes">
				{{ tournament?.isActive ? t("Finish in") : t("Start in") }} <b>{{ durationLeft }}</b>
			</AText>
			<AButton
				variant="primary"
				class="promo"
				data-tid="promo-tournament-go"
				:class="{ 'turnament-disabled': !tournament?.isActive }"
			>
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']">
					{{ tournament?.isActive ? t("Play now") : t("coming next") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	&::before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
		z-index: 0;
		background-color: var(--curug);
	}

	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.sub-title {
		display: block;
		min-height: 15px;
		margin-bottom: gutter(0.5);
	}

	.count-before {
		display: inline-flex;
		max-width: 40px;
	}

	.sweep-stakes {
		margin-bottom: gutter(3);
	}
}

.card-tournament {
	&.card-entries .coins,
	&:not(.card-entries) .entries {
		display: none;
	}

	.turnament-disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}
}

.top-left,
.top-right {
	position: absolute;
	top: 0;
	left: 0;
}

.top-right {
	left: auto;
	right: 0;

	&.custom {
		width: 100px;
		height: 100px;

		&::before {
			width: 148px;
			height: 44px;
			background: var(--geneva);
		}

		&:deep() {
			span {
				position: relative;
				top: -10px;
				left: 3px;
			}
		}
	}
}

.card-count {
	display: flex;
	align-items: center;
	gap: gutter(0.5);
}

button {
	width: 240px;
	height: 40px;
	gap: gutter(0.75);
}
</style>
